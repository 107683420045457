import React from 'react'
import ConsultingTemplate from 'components/templates/ConsultingTemplate/ConsultingTemplate'

export default function ConsultingIso14001Page() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'ISO 14001'

  const description =
    'Gestione e certificazione di attività, prodotti e servizi che possono presentare aspetti ambientali significativi e sui quali l’Organizzazione può esercitare un controllo partendo da una opportuna ed adeguata politica ambientale. Supportiamo le aziende nel conseguimento della certificazione ISO 14001 – EMAS II – ISO 50001.<br><br> <b>RISPARMIO ENERGETICO</b>: Le azioni atte a “capire” la performace energetica delle Organizzazioni e costruire il modello funzionale per poter identificate le aree significative rappresentano il vero “cuore” del presente punto. Eseguire una “radiografia” per capire cioè quanto, dove, come, perché, chi e cosa influenza il consumo è la base su cui costruire il risparmio. Solo un’analisi effettuata tenendo conto di tutti questi punti potrà portare alla definizione di tutti gli aspetti energetici dell’Organizzazione, con conseguente contenimento dei consumi e risparmio economico, sempre in ottica di “miglioramento continuo”.<br><br> <b>ENERGY MANAGEMENT</b>: intendendo la figura dell’esperto in gestione delle energie non come un singolo professionista, ma come un insieme di persone coinvolte nel progetto e allineate sugli obiettivi, persone che sono in grado di mettere a disposizione delle Organizzazioni le loro differenti professionalità finalizzate ad una corretta gestione energetica.<br><br> <b>CONTENIMENTO EMISSIONI CO2</b>: Per molto tempo ci si è concentrati prevalentemente sulla riduzione dei costi delle materie prime, del personale ecc, ma solo di rado ci si è concentrati sulla riduzione dei consumi energetici e di risorse o, peggio ancora, sulla riduzione delle emissioni di CO2.'

  return (
    <>
      <ConsultingTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
